
import Vue from "vue";
const emailRegex = new RegExp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
);

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.email = "";
          this.password = "";
          this.repeatPassword = "";
          (
            this.$refs.adminForm as HTMLFormElement & {
              resetValidation(): void;
            }
          ).resetValidation();
          this.$emit("close");
        }
      },
    },
  },
  data: () => ({
    email: "",
    password: "",
    repeatPassword: "",
    formValid: false,
    loading: false,
    rules: {
      email: [
        (val: string) =>
          (val && !!val.length) || "Veuillez entrer l'email d'administration",
        (val: string) => emailRegex.test(val) || "Cet email semble invalide",
      ],
      password: [
        (val: string) =>
          (val && val.length > 8) ||
          "Le mot de passe doit contenir minimum 8 caractères",
        (val: string) =>
          /[A-Z]/.test(val) ||
          "Le mot de passe doit contenir minimum une majuscule",
        (val: string) =>
          /[a-z]/.test(val) ||
          "Le mot de passe doit contenir minimum une minuscule",
        (val: string) =>
          /[0-9]/.test(val) ||
          "Le mot de passe doit contenir minimum un chiffre",
      ],
      repeatPassword: (context: any) => [
        (val: string) => {
          return (
            (val && val === context.password) ||
            "Les mots de passe ne correspondent pas"
          );
        },
      ],
    },
  }),
  methods: {
    async addAdmin() {
      (this.loading = true),
        await this.$store.dispatch("addAdmin", {
          email: this.email,
          password: this.password,
        });
      this.loading = false;
      this.showDialog = false;
    },
  },
});
